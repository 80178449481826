import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { MoonLoader } from "react-spinners";

const SingleInvoiceCustomer = () => {
    const serverURL = process.env.REACT_APP_SERVER_URL;
    const token = localStorage.getItem("token");
    const { user } = useContext(AuthContext);
    const { email } = useParams();
    const [dataLoading, setDataLoading] = useState(false);
    const [allInvoice, setAllInvoice] = useState([]);
    const [allCustomer, setAllCustomer] = useState([]);
    const [companySettingsState, setCompanySettingsState] = useState(null);

    function getMonthsOld(dateString) {
        const inputDate = new Date(dateString);
        const currentDate = new Date();

        const yearDiff = currentDate.getFullYear() - inputDate.getFullYear();
        const monthDiff = currentDate.getMonth() - inputDate.getMonth();
        const dayDiff = currentDate.getDate() - inputDate.getDate();

        let monthsOld = yearDiff * 12 + monthDiff;

        if (dayDiff < 0) {
            monthsOld -= 1;
        }

        return monthsOld;
    }

    const fetchAllInvoice = () => {
        const axiosConfig = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const body = JSON.stringify({ email: user.email, account: email });
        axios
            .post(`${serverURL}/api/v2/get-all-invoice/single-account`, body, axiosConfig)
            .then((res) => {
                setAllInvoice(res.data.filter((singleInvoice) => singleInvoice?.status !== "Paid"));
                fetchAllCustomers();
            })
            .catch((err) => {
                console.error("Error:", err);
                setDataLoading(false);
            });
    };

    const fetchAllCustomers = () => {
        const axiosConfig = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const body = JSON.stringify({ email: user.email, account: email });
        axios
            .post(`${serverURL}/api/v2/get-all-customer/single-account/allcustomer`, body, axiosConfig)
            .then((res) => {
                setAllCustomer(res.data);
                fetchCompanySettings();
            })
            .catch((err) => {
                console.error("Error:", err);
                setDataLoading(false);
            });
    };

    const fetchCompanySettings = () => {
        const axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const body = JSON.stringify({ email: user.email, account: email });
        axios.post(`${serverURL}/api/v2/single-company-settings`, body, axiosConfig)
            .then(res => {
                setCompanySettingsState(res.data);
                setDataLoading(false);
            })
            .catch(err => {
                console.error(
                    'Error:', err
                );
                setDataLoading(false);
            })
    }

    useEffect(() => {
        setDataLoading(true);
        fetchAllInvoice();
    }, []);
    if (dataLoading) {
        return (
            <div className="font-bold text-4xl flex mt-[35vh] bg-base-100 justify-center items-center">
                <MoonLoader color="#ff0000" size={45} />
            </div>
        );
    }

    const groupedInvoices = allInvoice.reduce((acc, invoice) => {
        const customerName = invoice.customer.customerName;
        if (!acc[customerName]) {
            acc[customerName] = [];
        }
        acc[customerName].push(invoice);
        return acc;
    }, {});

    const calculateCategoryTotals = (invoices) => {
        const categories = {
            current: 0,
            lessThan1Month: 0,
            oneMonth: 0,
            twoMonths: 0,
            threeMonths: 0,
            older: 0,
            total: 0,
        };

        invoices.forEach((invoice) => {
            const monthsOld = getMonthsOld(invoice.dueDate);
            const amount = parseFloat(invoice.dueAmount);
            if (monthsOld < 0) categories.current += amount;
            else if (monthsOld === 0) categories.lessThan1Month += amount;
            else if (monthsOld === 1) categories.oneMonth += amount;
            else if (monthsOld === 2) categories.twoMonths += amount;
            else if (monthsOld === 3) categories.threeMonths += amount;
            else if (monthsOld >= 4) categories.older += amount;
            categories.total += amount;
        });

        return categories;
    };

    const invoiceStatusOverview = (statusName) => {
        return allInvoice.filter((singleInvoice) => singleInvoice?.status === statusName).length;
    };

    return (
        <div className="pt-0 w-[95%] mx-auto mt-10">
            {!dataLoading && companySettingsState?.companyName === undefined ? (
                <div className="text-center">
                    <p className="font-poppins text-xl mt-10 text-center font-semibold">No Company Details Added.</p>
                </div>
            ) : !dataLoading && allCustomer.length === 0 ? (
                <div className="text-center">
                    <p className="font-poppins text-xl mt-10 text-center font-semibold">No Customer Added</p>
                </div>
            ) : (
                <div>
                    <div className="flex justify-between">
                        <div>
                            <p>
                                Draft invoices <span className="font-bold">{invoiceStatusOverview("Draft")}</span>
                            </p>
                            <p>
                                Awaiting payment <span className="font-bold">{invoiceStatusOverview("Awaiting Payment")}</span>
                            </p>
                            <p>
                                Overdue <span className="font-bold">{invoiceStatusOverview("Overdue")}</span>
                            </p>
                        </div>
                        <div>
                            <div className="font-bold text-lg">Ageing by due date</div>
                            <div>{companySettingsState?.companyName || "Please add your company details"}</div>
                            <div>As at {new Date().toLocaleDateString("en-GB")}</div>
                        </div>
                    </div>
                    <div className="bg-base shadow-lg mt-4 rounded overflow-x-auto">
                        <table className="min-w-[920px] w-full text-left rounded ">
                            <thead>
                                <tr className="bg-base-300">
                                    <th className="px-4 py-2">Contact</th>
                                    {/* <th className="px-4 py-2">Current</th> */}
                                    <th className="px-4 py-2">&lt; 1 Month</th>
                                    <th className="px-4 py-2">1 Month</th>
                                    <th className="px-4 py-2">2 Months</th>
                                    <th className="px-4 py-2">3 Months</th>
                                    <th className="px-4 py-2">Older</th>
                                    <th className="px-4 py-2">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allCustomer.map((singleCustomer, index) => {
                                    const invoices = allInvoice.filter((singleInvoice) => singleInvoice?.customer?.customerName === singleCustomer?.customer);
                                    const categoryTotals = calculateCategoryTotals(invoices);

                                    return (
                                        <tr key={index} className="border-t border-base-300 bg-base-200">
                                            <td className="px-4 py-2">
                                                <Link to={`/app/customer/${singleCustomer?._id}`}>{singleCustomer?.customer}</Link>
                                            </td>
                                            {/* <td className="px-4 py-2">{categoryTotals.current.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td> */}
                                            <td className="px-4 py-2">{categoryTotals.lessThan1Month.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td className="px-4 py-2">{categoryTotals.oneMonth.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td className="px-4 py-2">{categoryTotals.twoMonths.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td className="px-4 py-2">{categoryTotals.threeMonths.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td className="px-4 py-2">{categoryTotals.older.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td className="px-4 py-2">{categoryTotals.total.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                        </tr>
                                    );
                                })}
                                {/* {Object.keys(groupedInvoices).map((customerName, index) => {
                                        const invoices = groupedInvoices[customerName];
                                        console.log(invoices);
                                        const categoryTotals = calculateCategoryTotals(invoices);

                                        return (
                                            <tr key={index} className="border-t">
                                                <td className="px-4 py-2">{customerName}</td>
                                                <td className="px-4 py-2">{categoryTotals.current.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                <td className="px-4 py-2">{categoryTotals.lessThan1Month.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                <td className="px-4 py-2">{categoryTotals.oneMonth.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                <td className="px-4 py-2">{categoryTotals.twoMonths.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                <td className="px-4 py-2">{categoryTotals.threeMonths.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                <td className="px-4 py-2">{categoryTotals.older.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                <td className="px-4 py-2">{categoryTotals.total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            </tr>
                                        );
                                    })} */}
                                {/* Total Row */}
                                <tr className="border-t border-base-300 bg-base-300 font-bold">
                                    <td className="px-4 py-2">Total</td>
                                    <td className="px-4 py-2">
                                        {Object.values(groupedInvoices)
                                            .reduce((acc, invoices) => acc + calculateCategoryTotals(invoices).current, 0)
                                            .toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </td>
                                    {/* <td className="px-4 py-2">{Object.values(groupedInvoices).reduce((acc, invoices) => acc + calculateCategoryTotals(invoices).lessThan1Month, 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td> */}
                                    <td className="px-4 py-2">
                                        {Object.values(groupedInvoices)
                                            .reduce((acc, invoices) => acc + calculateCategoryTotals(invoices).oneMonth, 0)
                                            .toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </td>
                                    <td className="px-4 py-2">
                                        {Object.values(groupedInvoices)
                                            .reduce((acc, invoices) => acc + calculateCategoryTotals(invoices).twoMonths, 0)
                                            .toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </td>
                                    <td className="px-4 py-2">
                                        {Object.values(groupedInvoices)
                                            .reduce((acc, invoices) => acc + calculateCategoryTotals(invoices).threeMonths, 0)
                                            .toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </td>
                                    <td className="px-4 py-2">
                                        {Object.values(groupedInvoices)
                                            .reduce((acc, invoices) => acc + calculateCategoryTotals(invoices).older, 0)
                                            .toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </td>
                                    <td className="px-4 py-2">
                                        {Object.values(groupedInvoices)
                                            .reduce((acc, invoices) => acc + calculateCategoryTotals(invoices).total, 0)
                                            .toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SingleInvoiceCustomer;
