import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from '../../firebase/firebase.config';
import { AiOutlineArrowLeft, AiOutlineEye } from 'react-icons/ai';
import { AuthContext } from '../../context/AuthContext';
import { handleAdminRoute, handleGoBack } from '../../functions/functions';
import uploadIcon from "./../../assets/upload.svg";
import { PDFDownloadLink } from '@react-pdf/renderer';
import BusinessLoanPDF from '../../pdf/BusinessLoanPDF/BusinessLoanPDF';
import { PacmanLoader } from 'react-spinners';
import axios from 'axios';
import useIban from '../../hooks/useIban';
import Agreement1 from '../../pdf/AgreementPDF/Agreement1';

const statusArray = ['Initial Review', 'Golden Data', 'The Great Takeover', 'Pre-Underwriting', 'Pre-Offer', 'Underwriting', 'Extra docs ob', 'Awaiting Offer', 'Offer', 'Contract Sent', 'Awaiting Funding', 'Last Chance', 'Cancelled', 'Cancelled for ever']

const EditBusinessLoan = ({ loanData, loading, setRefresh, refresh, statusStep, setLoading }) => {
    const { user, adminData } = useContext(AuthContext);
    const [history, setHistory] = useState([]);
    const [teamNote, setTeamNote] = useState('');
    const [renewLoanData, setRenewLoanData] = useState({});
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [bankToken, setBankToken] = useState();
    const [openBankingRequisition, setOpenBankingRequisition] = useState(null);
    const [uploadingLoader, setUploadingLoader] = useState(false);
    const [tempUpdateValue, setTempUpdateValue] = useState({});
    const [dataError, setDataError] = useState(false);
    const [totalStaffComs, settotalStaffComs] = useState(0);
    const [agreementData, setAgreementData] = useState({});

    const handleWheel = (e) => {
        e.preventDefault(); // Prevent scrolling
        e.target.blur();    // Remove focus to disable scroll change
    };

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            e.preventDefault(); // Prevent arrow key changes
        }
    };

    //custom hook
    const { accountData, obloading } = useIban(openBankingRequisition?.accounts);

    const navigate = useNavigate();


    const [formData, setFormData] = useState({
        companyComs: loanData["company-coms"] || '',
        staffComs: loanData["staff-coms"] || '',
    });

    const handleCompanyComsChange = (event) => {
        const companyComsValue = event.target.value;
        setFormData({
            ...formData,
            companyComs: companyComsValue,
            staffComs: calculateStaffComs(companyComsValue),
        });
        setTempUpdateValue(prev => ({
            ...prev,
            'company-coms': companyComsValue,
            'staff-coms': calculateStaffComs(companyComsValue),
        }));
    };

    const calculateStaffComs = (companyComsValue) => {
        return (parseFloat(companyComsValue) * 0.01).toFixed(2);
    };

    useEffect(() => {
        setRenewLoanData({ ...loanData });
        fetch(`${process.env.REACT_APP_SERVER_URL}/get-loan-edit-history/${loanData._id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ email: user.email })
        })
            .then(response => response.json())
            .then(data => {
                setHistory(data.history.reverse());
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, [loanData, user])


    const deleteRequest = async (id) => {
        try {
            fetch(`${process.env.REACT_APP_SERVER_URL}/trash-loan-request`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(loanData)
            })
                .then(response => response.json())
                .then(result => {
                    if (result.acknowledged) {
                        fetch(`${process.env.REACT_APP_SERVER_URL}/loan-request/${id}`, {
                            method: 'DELETE',
                        });
                        navigate(handleAdminRoute(adminData));
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        } catch (error) {
            console.error('Error:', error);
        }
    };

    // Renew Button Function
    const submitLoanRequest = (e) => {
        e.preventDefault()
        // delete renewLoanData.documents;
        delete renewLoanData._id;
        delete renewLoanData.type;
        delete renewLoanData.status;
        delete renewLoanData.createdDate;
        delete renewLoanData["funded-amount"];

        fetch(`${process.env.REACT_APP_SERVER_URL}/loan-request`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ...renewLoanData,
                "type": "Renewal Loan",
                "status": "Pre-Underwriting",
                "createdDate": new Date().toISOString(),
            })
        })
            .then(response => response.json())
            .then(result => {
                // upload pdf with inserted id
                handleGoBack(navigate, adminData)
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    // Function to handle checkbox change
    const handleCheckboxChange = (e, field, subField) => {
        const isChecked = e.target.checked;
        // const updatedLoanData = { ...loanData };

        // Call the new API endpoint to update the checkbox status dynamically
        fetch(`${process.env.REACT_APP_SERVER_URL}/update-checkbox/${loanData._id}/${field}/${subField}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ updatedValue: isChecked })
        })
            .then(response => response.json())
            .then(result => {
                // Handle the result if needed
                console.log(result);
            })
            .catch(error => {
                console.error('Error:', error);
            });

        // Update the state locally
        // updatedLoanData.documents[field][subField] = isChecked;
        setRefresh(!refresh);
    };

    const handleNoteSubmit = () => {
        // const note = user["displayName"] + " - " + teamNote
        const userDisplayName = user["displayName"];
        const currentTime = new Date();
        const options = { timeZone: "Europe/London", year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
        const formattedTime = currentTime.toLocaleString("en-GB", options);

        const note = {
            name: `${userDisplayName} - ${formattedTime}`,
            note: teamNote
            // other properties of the note object...
        };
        if (teamNote.length !== 0) {
            fetch(`${process.env.REACT_APP_SERVER_URL}/add-note/${loanData?._id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ teamNote: note }),
            })
                .then(response => response.json())
                .then(result => {
                    if (result.success) {
                        // Handle success, maybe update UI or show a notification
                        console.log(result.message);
                        setRefresh(!refresh)
                        setTeamNote(''); // Clear the input after submission
                    } else {
                        // Handle failure, maybe show an error message
                        console.error(result.message);
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    };

    const handleFileChange = async (e, field) => {
        setUploadingLoader(true);
        const files = e.target.files;
        if (!files || files.length === 0) return;
        console.log(field);
        try {
            const attachment = loanData?.documents?.[field]?.attachment;
            let downloadURLs;

            if (Array.isArray(attachment)) {
                // If it's an array, create a copy of the array
                downloadURLs = [...attachment];
            } else if (typeof attachment === 'string') {
                // If it's a string, create an array with the string as its only element
                downloadURLs = [attachment];
            } else {
                // Handle other cases as needed
                downloadURLs = [];
            }

            for (const file of files) {
                const pdfREF = ref(storage, `pdf/${loanData?._id}/${file.name}`);

                await uploadBytes(pdfREF, file);

                const downloadURL = await getDownloadURL(pdfREF);
                downloadURLs.push({ url: downloadURL, name: file.name });
            }

            const pdfUPLOAD = {
                "attachment": downloadURLs
            };

            fetch(`${process.env.REACT_APP_SERVER_URL}/edit-loan-field/${loanData._id}/${field}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ updatedValue: pdfUPLOAD })
            })
                .then(response => response.json())
                .then(result => {
                    setRefresh(!refresh);
                    setUploadingLoader(false);
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        } catch (error) {
            console.error('Error uploading files:', error);
        }
    };

    const addToBook = () => {
        const bookInfo = {
            "funder": loanData?.type,
            "companyname": loanData["business-name"],
            "loanamount": loanData["required-amount"],
            "repayment": loanData["funded-amount"],
            "factor": 0,
            "comrate": 0,
            "comamo": loanData['company-coms'],
            "status": loanData?.status,
            "closer": loanData["funding-manager"],
            "note": "",
            "user": user.email,
            "createdDate": new Date().toISOString()
        };
        fetch(`${process.env.REACT_APP_SERVER_URL}/submit-deals`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(bookInfo)
        })
            .then(response => response.json())
            .then(result => {
                console.log(result);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    const handleCallBackSubmit = async () => {
        // console.log(selectedDate, selectedTime, dateTime);s
        if (!selectedDate || !selectedTime) {
            return;
        }

        try {
            // Make API call here using axios
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/edit-loan/${loanData._id}`, { callBack: { date: selectedDate, time: selectedTime } }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.data.modifiedCount) {
                loanData.callBack = { date: selectedDate, time: selectedTime }
            }
            console.log('Response:', response?.data);
            // Handle response as needed
        } catch (error) {
            console.error('Error:', error);
        }
    };

    // Function to handle date change
    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    // Function to handle time change
    const handleTimeChange = (event) => {
        setSelectedTime(event.target.value);
    };

    // Function to generate time slots
    const generateTimeSlots = () => {
        const timeSlots = [];
        const startTime = 8 * 60; // 8:00 AM in minutes
        const endTime = 20 * 60; // 8:00 PM in minutes
        const interval = 15; // 15 minutes interval

        for (let i = startTime; i < endTime; i += interval) {
            const hours = Math.floor(i / 60);
            const minutes = i % 60;
            const ampm = hours >= 12 ? 'PM' : 'AM';
            const displayHours = hours % 12 === 0 ? 12 : hours % 12;
            const formattedTime = `${displayHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${ampm}`;
            timeSlots.push(formattedTime);
        }

        return timeSlots;
    };

    const copyToClipboard = () => {
        const link = `${process.env.REACT_APP_OPEN_BANKING + '/' + loanData._id}`;
        navigator.clipboard.writeText(link).then(() => {
            alert('Link copied to clipboard!');
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };

    const fetchAllRequisitions = async () => {
        const url = `${process.env.REACT_APP_SERVER_URL}/gocardless-token`;
        try {
            const response = await fetch(url, {
                method: 'GET'
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const responseData = await response.json();

            try {
                setLoading(true);
                const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/gocardless-requisitions/${loanData['open-banking-ref']}`, {
                    token: responseData?.access
                });
                setOpenBankingRequisition(response.data);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }

            localStorage.setItem('bankToken', responseData?.access)
            setBankToken(responseData?.access);
        } catch (error) {
            console.error('Error:', error);
        }

    };

    useEffect(() => {
        if (loanData['open-banking-ref']) {
            fetchAllRequisitions();
        }
    }, [])

    const exportData = async () => {
        const accountId = document.getElementById('openBankingAccountID').value;
        try {
            setLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/gocardless-account-transactions/${accountId}`, {
                token: bankToken
            });

            const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
                JSON.stringify(response.data)
            )}`;
            const link = document.createElement("a");
            link.href = jsonString;
            link.download = `${loanData['business-name']}.json`;

            link.click();

        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const generateFileName = () => {
        // Get the business name, convert it to lower case, and replace spaces with underscores
        const formattedBusinessName = loanData["business-name"]
            .toLowerCase()
            .replace(/\s+/g, '_');

        const date = new Date().toLocaleDateString('en-GB').replace(/\//g, '-');
        return `loan_data_${formattedBusinessName}_${date}.pdf`;
    };

    const handleChangedValue = (e) => {
        setTempUpdateValue(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    }

    const newUpdateFunction = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token'); // Insert your JWT token here
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/v2/update/loan-request`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    email: user.email,
                    data: tempUpdateValue,
                    _id: loanData._id
                })
            });

            if (!response.ok) {
                setDataError(true);
                setLoading(false);
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const responseData = await response.json();
            console.log('Response:', responseData);
            setLoading(false);
            setRefresh(!refresh);
        } catch (error) {
            setDataError(true);
            console.error('Error:', error);
        }
    }

    const newUpdateFunctionBack = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token'); // Insert your JWT token here
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/v2/update/loan-request`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    email: user.email,
                    data: tempUpdateValue,
                    _id: loanData._id
                })
            });

            if (!response.ok) {
                setLoading(false);
                setDataError(true);
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const responseData = await response.json();
            console.log('Response:', responseData);
            setLoading(false);
            setRefresh(!refresh);
            navigate(-1)
        } catch (error) {
            setDataError(true);
            console.error('Error:', error);
        }
    }

    useEffect(() => {

    }, [])

    const getTotalStaffComs = () => {
        const staffComs = parseInt(loanData["staff-coms"]);
        if (adminData.role === 'super-admin' && !loading) {
            console.log(parseInt(loanData["staff-coms"]));
            if (loanData['funding-manager'] === 'Jon') {
                settotalStaffComs((4.5 * staffComs));
            } else if (loanData['funding-manager'] === 'Millie') {
                settotalStaffComs(4.5 * staffComs);
            } else if (loanData['funding-manager'] === 'Smit') {
                settotalStaffComs(6 * staffComs);
            } else if (loanData['funding-manager'] === 'Mark') {
                settotalStaffComs(6 * staffComs);
            }
        }
    }

    const fetchAgreementData = () => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v2/get-agreement`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ email: user.email, id: loanData._id })
        })
            .then(response => response.json())
            .then(data => {
                setAgreementData(data);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    const handleAgreementCopy = () => {
        navigator.clipboard.writeText(`http://readysteadyportal.com/agreement-form/${loanData?._id}`)
            .then(() => {
                alert('Link copied to clipboard!');
            })
            .catch(err => {
                console.error('Failed to copy the text to clipboard', err);
            });
    };

    useEffect(() => {
        getTotalStaffComs();
        fetchAgreementData();
    }, [loanData])


    const [selectedLender, setSelectedLender] = useState(null);
    const lenderArray = [
        'New Deal',
        "YouLend",
        "365 Finance",
        "IWOCA",
        "Funding Circle",
        "Funding Alternative",
        "Swiftfund",
        "Maxcap",
        "Bizcap",
        "Capify",
        "Playter",
        "Bibby"
    ];
    const uploadToNewKanban = () => {
        if (!selectedLender) {
            alert("You need to choose a lender!");
            return; // Exit the function if no lender is selected
        }
        setLoading(true);
        const data = {
            "lenders": {
                [selectedLender]: {
                    "status": loanData?.status,
                    "show-in-kanban": "Yes",
                    "company-coms": loanData['company-coms'],
                    "staff-coms": loanData['staff-coms'],
                    "offer-repayment": loanData['funded-amount'],
                    "team-notes": loanData?.teamNotes,
                }
            },
            "funding-manager": loanData['funding-manager'],
            "business-name": loanData['business-name'],
            "business-type": loanData['business-type'],
            "business-address": loanData['business-address'],
            "business-owner-name": loanData['business-contact-name'],
            "birth-date": loanData?.dateOfBirth,
            "home-address": loanData['home-address'],
            "business-email": loanData['business-email'],
            "business-phone": loanData['business-phone'],
            "required-amount": loanData['required-amount'],
            "funded-amount": loanData['funded-amount'],
            "country": "£",
            "avarage-monthly-turnover": loanData['business-turnover'],
            "home-owner": loanData['home-owner'],
            "lender-note": loanData['business-notes'],
            "loan-purpose": loanData['loan-purpose'],
            "overdraft-limit": loanData['overdraft-limit'],
            "documents": loanData?.documents,
            "other-loans": loanData['otherLoans'],
            "marchent-turnover": loanData['marchent-turnover'],
            "do-they-invoice": loanData?.invoice,
            "callBack": loanData?.callBack,
            "metadata": {
                "createdBy": loanData?.user || user?.email,
                "createdAt": loanData?.createdDate || new Date(),
            },
            email: user?.email
        }

        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v3/new-deal`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(result => {
                console.log(result);
                setLoading(false);
                alert("Your Data Is Uploaded In New Kanban");
                // navigate(-1);
            })
            .catch(error => {
                setLoading(false);
                console.error('Error:', error);
            });
    }

    if (loading) {
        return <div className='text-4xl mt-[35vh] flex  justify-center items-center'>
            <PacmanLoader color="#e63a27" />
        </div>
    }

    return (
        <div>
            {/* <img src={`${process.env.REACT_APP_SERVER_URL}/uploads/signature/${agreementData?.signature}`} />
            {agreementData?.signature && <PDFViewer width={800} height={705} showToolbar={false}>
                <Agreement1 clientName={agreementData?.clientName} businessName={agreementData?.businessName} businessAddress={agreementData?.businessAddress} signature={agreementData?.signature} fileName="Loan Data"></Agreement1>
            </PDFViewer>} */}
            <div>
                {
                    dataError && <div role="alert" className="alert alert-error mt-5">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 shrink-0 stroke-current"
                            fill="none"
                            viewBox="0 0 24 24">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <span>Error! Something Went Wrong, Please try again or contact Steav.</span>
                    </div>
                }
                <div className='mt-5 flex gap-3 justify-between'>
                    <div className='flex gap-3'>
                        <Link to="#" onClick={() => navigate(-1)} className='btn bg-white hover:text-white hover:bg-primary text-gray-500'><AiOutlineArrowLeft />Go Back</Link>
                        <button onClick={submitLoanRequest} className='btn bg-white hover:text-white hover:bg-primary text-gray-500'>Renew</button>
                        <PDFDownloadLink fileName={generateFileName()} document={<BusinessLoanPDF loanData={loanData}></BusinessLoanPDF>} className='btn bg-white hover:text-white hover:bg-primary text-gray-500'>PDF</PDFDownloadLink>
                    </div>
                    {
                        adminData?.role === "super-admin" && <div className='flex items-center'>
                            <p className="text-lg font-bold">Total Staff Coms: £{parseInt(totalStaffComs).toLocaleString('en-us')}</p>
                        </div>
                    }
                    <div className='flex items-center gap-3'>
                        {
                            <select
                                value={selectedLender}
                                onChange={(e) => setSelectedLender(e.target.value)}
                                className='p-3 rounded-lg bg-base-300'
                            >
                                <option value="">-- Select Lender --</option>
                                {lenderArray.map((lender, index) => (
                                    <option key={index} value={lender}>
                                        {lender}
                                    </option>
                                ))}
                            </select>
                        }
                        {
                            adminData?.role === 'super-admin' && <button onClick={uploadToNewKanban} className='button'>Add To New Kanban</button>
                        }
                        <div className='flex gap-3'>
                            <button onClick={handleAgreementCopy} className='btn'>Copy Agreement Link</button>
                            {agreementData?.signature && <PDFDownloadLink fileName={generateFileName()} document={<Agreement1 clientName={agreementData?.clientName} businessName={agreementData?.businessName} businessAddress={agreementData?.businessAddress} signature={agreementData?.signature} fileName="Loan Data"></Agreement1>} className='btn'>Download Agreement</PDFDownloadLink>}
                            {(adminData.role === "super-admin" && (loanData.status === "Funded" || loanData.status === "Funded This Month")) && (
                                <button onClick={addToBook} className='btn bg-primary hover:text-gray-500 hover:bg-white text-white' style={{ boxShadow: "rgba(0, 0, 0, 0.05) 0px 20px 27px 0px" }}>
                                    Add to Book
                                </button>
                            )}
                            {adminData.role === "super-admin" && (
                                <button onClick={() => deleteRequest(loanData._id)} className='btn bg-primary hover:text-gray-500 hover:bg-white text-white' style={{ boxShadow: "rgba(0, 0, 0, 0.05) 0px 20px 27px 0px" }}>
                                    Delete
                                </button>
                            )}
                            <div className="drawer drawer-end">
                                {adminData.role === "super-admin" && <input id="my-drawer" type="checkbox" className="drawer-toggle" />}
                                <div className="drawer-content">
                                    {/* Page content here */}
                                    <label htmlFor="my-drawer" className="btn bg-primary hover:text-gray-500 hover:bg-white text-white drawer-button">
                                        History
                                    </label>
                                </div>
                                <div className="drawer-side w-full z-50">
                                    <label htmlFor="my-drawer" className="drawer-overlay"></label>
                                    <div className="menu p-4 w-full min-h-full bg-base-200 text-base-content relative">
                                        {/* Close icon */}
                                        <label htmlFor="my-drawer" className="absolute text-4xl right-10 top-10 cursor-pointer text-gray-500">
                                            &#10005;
                                        </label>
                                        {/* Sidebar content here */}
                                        <h1 className='text-3xl font-bold uppercase text-center mb-5 mt-20'>Timeline</h1>
                                        <div className="container mx-auto">
                                            <div className="overflow-x-auto">
                                                <table className="min-w-full border-base-300 border-2">
                                                    <thead className="bg-base-200">
                                                        <tr>
                                                            <th className="px-6 bg-base-300 py-3 border-b border-base-300 text-left text-sm leading-4 uppercase font-medium">Name</th>
                                                            <th className="px-6 bg-base-300 py-3 border-b border-base-300 text-left text-sm leading-4 uppercase font-medium">Date</th>
                                                            <th className="px-6 bg-base-300 py-3 border-b border-base-300 text-left text-sm leading-4 uppercase font-medium">Time</th>
                                                            <th className="px-6 bg-base-300 py-3 border-b border-base-300 text-left text-sm leading-4 uppercase font-medium">Field</th>
                                                            <th className="px-6 bg-base-300 py-3 border-b border-base-300 text-left text-sm leading-4 uppercase font-medium">Previous Value</th>
                                                            <th className="px-6 bg-base-300 py-3 border-b border-base-300 text-left text-sm leading-4 uppercase font-medium">New Value</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            history.length !== 0 && history.map((timelineHistory, i) => (<tr key={i} className='hover:bg-base-300'>
                                                                <td className="px-6 py-4 border-b border-base-300 text-sm leading-5">{timelineHistory.updatedBy}</td>
                                                                <td className="px-6 py-4 border-b border-base-300 text-sm leading-5">{timelineHistory.date}</td>
                                                                <td className="px-6 py-4 border-b border-base-300 text-sm leading-5">{timelineHistory.time}</td>
                                                                <td className="px-6 py-4 border-b border-base-300 text-sm leading-5">{timelineHistory.field}</td>
                                                                <td className="px-6 py-4 border-b border-base-300 text-sm leading-5">{timelineHistory.oldValue}</td>
                                                                <td className="px-6 py-4 border-b border-base-300 text-sm leading-5">{timelineHistory.newValue}</td>
                                                            </tr>))
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/* Step Progress Bar */}
                <div className='flex items-center justify-center mr-10 my-10'>
                    <ul className="steps">
                        <li className={`step ${statusStep >= 1 && "step-primary"}`}>Initial Review</li>
                        <li className={`step ${statusStep >= 3 && "step-primary"}`}>Golden Data</li>
                        <li className={`step ${statusStep >= 4 && "step-primary"}`}>The Great Takeover</li>
                        <li className={`step ${statusStep >= 5 && "step-primary"}`}>Pre-Underwriting</li>
                        <li className={`step ${statusStep >= 6 && "step-primary"}`}>Pre-Offer</li>
                        <li className={`step ${statusStep >= 7 && "step-primary"}`}>Underwriting</li>
                        <li className={`step ${statusStep >= 8 && "step-primary"}`}>Extra docs ob</li>
                        <li className={`step ${statusStep >= 9 && "step-primary"}`}>Awaiting Offer</li>
                        <li className={`step ${statusStep >= 10 && "step-primary"}`}>Offer</li>
                        <li className={`step ${statusStep >= 11 && "step-primary"}`}>Contract Sent</li>
                        <li className={`step ${statusStep >= 12 && "step-primary"}`}>Awaiting Funding</li>
                        <li className={`step ${statusStep >= 13 && "step-primary"}`}>Funded This Month</li>
                        <li className={`step ${statusStep >= 14 && "step-primary"}`}>Funded</li>
                        <li className={`step ${statusStep >= 15 && "step-primary"}`}>Cancelled</li>
                    </ul>
                </div>
                {/* End Step Progress Bar */}
                <form className="mt-8 mr-10 mx-auto">
                    <div className='grid grid-cols-3 gap-3'>
                        {
                            adminData?.team !== 'call-center' && <div>
                                <label htmlFor="status" className='text-sm mb-1 block'>Status:</label>
                                <select name='status' id='status' className="p-3 rounded-lg bg-base-300 w-full" onChange={e => handleChangedValue(e)} defaultValue={loanData["status"]}>
                                    <option value={loanData["status"]}>{loanData["status"]}</option>
                                    {statusArray.map((status, index) => (
                                        user?.email === 'millie@sedgefunding.co.uk' || user?.email === 'smit@readysteadyfinance.co.uk' || adminData?.role === 'super-admin' ? <option value={status} key={index}>{status}</option> : statusArray.indexOf(loanData["status"]) < statusArray.indexOf(status) && <option value={status}>{status}</option>
                                    ))}
                                </select>
                            </div>
                        }

                        {adminData?.team !== 'call-center' && <div>
                            <label htmlFor="type" className=' text-sm mb-1 block'>Loan Type:</label>
                            <select
                                name='type'
                                id='type'
                                className="p-3 rounded-lg bg-base-300 w-full"
                                onChange={e => handleChangedValue(e)}
                                defaultValue={loanData.type}
                                disabled={!(
                                    user?.email === 'millie@sedgefunding.co.uk' ||
                                    user?.email === 'smit@readysteadyfinance.co.uk' ||
                                    adminData?.role === 'super-admin'
                                )}
                            >
                                <option value={loanData.type}>{loanData.type}</option>
                                <option value="My Cash Line">My Cash Line</option>
                                <option value="Bizcap">Bizcap</option>
                                <option value="MCA">MCA</option>
                                <option value="Business Loan">Business Loan</option>
                                <option value="Grants">Grants</option>
                                <option value="Renewal Loan">Renewal Loan</option>
                                <option value="Renewal MCA">Renewal MCA</option>
                                <option value="New Lead">New Lead</option>
                                <option value="YouLend">YouLend</option>
                                <option value="Liberis">Liberis</option>
                                <option value="365">365</option>
                                <option value="Invoice">Invoice</option>
                                <option value="Seneca">Seneca</option>
                                <option value="Funding Alt">Funding Alt</option>
                                <option value="Swiftfund">Swiftfund</option>
                                <option value="Maxcap">Maxcap</option>
                                <option value="Funding Circle">Funding Circle</option>
                                <option value="Sedge">Sedge</option>
                                <option value="IWOCA">IWOCA</option>
                                <option value="Capify">Capify</option>
                                <option value="Playter">Playter</option>
                                <option value="Penny">Penny</option>
                                <option value="Sapi">Sapi</option>
                            </select>
                        </div>}

                        <div>
                            <label htmlFor="fundingManager" className='text-sm mb-1 block'>Funding Manager:</label>
                            <select name='funding-manager' id='fundingManager' className="p-3 rounded-lg bg-base-300 w-full" onChange={e => handleChangedValue(e)} defaultValue={loanData['funding-manager'] || ''}>
                                <option value={loanData['funding-manager']}>{loanData['funding-manager'] || 'Funding Manager'}</option>
                                <option value="Wayne">Wayne</option>
                                <option value="Peter">Peter</option>
                                <option value="Jon">Jon</option>
                                <option value="Millie">Millie</option>
                                <option value="Smit">Smit</option>
                                <option value="Mark">Mark</option>
                                <option value="Becher">Becher</option>
                                <option value="Don">Don</option>
                                <option value="Carl">Carl</option>
                                <option value="Janne">Janne</option>
                                <option value="Khymir">Khymir</option>
                            </select>
                        </div>
                        <div>
                            <label htmlFor="businessName" className=' text-sm mb-1 block'>Business Name:</label>
                            <input onChange={e => handleChangedValue(e)} name='business-name' id='businessName' type="text" placeholder="Business Name" defaultValue={`${loanData["business-name"]}`} className="p-3 rounded-lg bg-base-300 w-full" required />
                        </div>
                        <div>
                            <label htmlFor="businessType" className=' text-sm mb-1 block'>Business Type:</label>
                            <select onChange={e => handleChangedValue(e)} name='business-type' id='businessType' className="p-3 rounded-lg bg-base-300 w-full" defaultValue={loanData["business-type"] || ''} required>
                                <option value={loanData["business-type"]} disabled>Current Type - {loanData["business-type"]}</option>
                                <option value="Ltd">Ltd</option>
                                <option value="PLC">PLC</option>
                                <option value="LLP">LLP</option>
                                <option value="Sole Trader">Sole Trader</option>
                                <option value="Partnership">Partnership</option>
                            </select>

                        </div>
                        <div>
                            <label htmlFor="businessAddress" className=' text-sm mb-1 block'>Business Address:</label>
                            <input onChange={e => handleChangedValue(e)} defaultValue={`${loanData["business-address"]}`} name='business-address' id='businessAddress' type="text" placeholder="Business Address" className="p-3 rounded-lg bg-base-300 w-full" required />
                        </div>

                        <div>
                            <label htmlFor="contactName" className=' text-sm mb-1 block'>Business Contact Name (Owner):</label>
                            <input onChange={e => handleChangedValue(e)} defaultValue={`${loanData["business-contact-name"]}`} name='business-contact-name' type="text" placeholder="Contact Name" className="p-3 rounded-lg bg-base-300 w-full" required />
                        </div>
                        <div>
                            <label htmlFor="dateOfBirth" className=' text-sm mb-1 block'>Date Of Birth:</label>
                            <input onChange={e => handleChangedValue(e)} defaultValue={`${loanData["dateOfBirth"]}`} name='dateOfBirth' type="date" placeholder="Date of birth" className="p-3 rounded-lg bg-base-300 w-full" required />
                        </div>
                        <div>
                            <label htmlFor="home-address" className=' text-sm mb-1 block'>Home Address:</label>
                            <input onChange={e => handleChangedValue(e)} defaultValue={`${loanData["home-address"]}`} name='home-address' id='home-address' type="text" placeholder="Home Address" className="p-3 rounded-lg bg-base-300 w-full" required />
                        </div>
                        <div>
                            <label htmlFor="businessEmail" className=' text-sm mb-1 block'>Business Email:</label>
                            <input onChange={e => handleChangedValue(e)} defaultValue={`${loanData["business-email"]}`} name='business-email' id='businessEmail' type="text" placeholder="Email" className="p-3 rounded-lg bg-base-300 w-full" required />
                        </div>
                        <div>
                            <label htmlFor="phone" className=' text-sm mb-1 block'>Business Phone Number:</label>
                            <input onChange={e => handleChangedValue(e)} defaultValue={`${loanData["business-phone"]}`} name='business-phone' id='phone' type="text" placeholder="Phone Number" className="p-3 rounded-lg bg-base-300 w-full" required />
                        </div>

                        <div>
                            <label htmlFor="homeOwner" className=' text-sm mb-1 block'>Homeowner / Renting:</label>
                            <input onChange={e => handleChangedValue(e)} defaultValue={`${loanData["home-owner"]}`} name='home-owner' id='homeOwner' type="text" placeholder="Home Owner / Renting" className="p-3 rounded-lg bg-base-300 w-full" required />
                        </div>
                        <div>
                            <label htmlFor="requiredAmount" className=' text-sm mb-1 block'>Required Amount:</label>
                            <input onWheel={handleWheel} onKeyDown={handleKeyDown} onChange={e => handleChangedValue(e)} defaultValue={`${loanData["required-amount"]}`} name='required-amount' id='requiredAmount' type="number" placeholder="Required Amount" className="p-3 rounded-lg bg-base-300 w-full" required />
                        </div>
                        <div>
                            <label htmlFor="fundedAmount" className=' text-sm mb-1 block'>Funded Amount:</label>
                            <input onWheel={handleWheel} onKeyDown={handleKeyDown} onChange={e => handleChangedValue(e)} id='fundedAmount' defaultValue={loanData['funded-amount']} name='funded-amount' type="number" placeholder="Funded Amount" className="p-3 rounded-lg bg-base-300 w-full" />
                        </div>
                        <div>
                            <label htmlFor="notes" className=' text-sm mb-1 block'>Note For Customer:</label>
                            <input onChange={e => handleChangedValue(e)} defaultValue={`${loanData["business-notes"]}`} name='business-notes' type="text" placeholder="Notes" className="p-3 rounded-lg bg-base-300 w-full" />
                        </div>
                        <div>
                            <label htmlFor="businessTurnover" className=' text-sm mb-1 block'>Average Monthly Turnover:</label>
                            <input onChange={e => handleChangedValue(e)} defaultValue={`${loanData["business-turnover"]}`} name='business-turnover' type="text" placeholder="Monthly Turnover" className="p-3 rounded-lg bg-base-300 w-full" />
                        </div>
                        <div>
                            <label htmlFor="marchent-turnover" className=' text-sm mb-1 block'>Marchent Turnover:</label>
                            <input onChange={e => handleChangedValue(e)} defaultValue={`${loanData["marchent-turnover"]}`} name='marchent-turnover' type="text" placeholder="Marchent Turnover" className="p-3 rounded-lg bg-base-300 w-full" />
                        </div>
                        <div>
                            <label htmlFor="loanPurpose" className=' text-sm mb-1 block'>Purpose of Loan:</label>
                            <input onChange={e => handleChangedValue(e)} defaultValue={`${loanData["loan-purpose"]}`} name='loan-purpose' type="text" placeholder="Loan Purpose" className="p-3 rounded-lg bg-base-300 w-full" />
                        </div>
                        <div>
                            <label htmlFor="overdraft" className=' text-sm mb-1 block'>Overdraft Limit:</label>
                            <input onChange={e => handleChangedValue(e)} defaultValue={`${loanData["overdraft-limit"]}`} name='overdraft-limit' type="text" placeholder="Overdraft Limit" className="p-3 rounded-lg bg-base-300 w-full" />
                        </div>
                        <div>
                            <label htmlFor="invoice" className=' text-sm mb-1 block'>Do they Invoice:</label>
                            <input name='invoice' id='invoice' type="text" placeholder="Do they Invoice" onChange={e => handleChangedValue(e)} defaultValue={`${loanData.invoice}`} className="p-3 rounded-lg bg-base-300 w-full" required />
                        </div>
                        {
                            adminData.role === 'super-admin' && <div>
                                <label htmlFor="companyComs" className=' text-sm mb-1 block'>Company Coms:</label>
                                <input defaultValue={loanData["company-coms"]} onChange={handleCompanyComsChange} name='companyComs' type="text" placeholder="Company Coms" className="p-3 rounded-lg bg-base-300 w-full" />
                            </div>
                        }
                        {/* {
                            adminData.role === 'super-admin' && <div>
                                <label htmlFor="staffComs" className='text-sm mb-1 block'>Staff Coms:</label>
                                <input readOnly value={formData?.staffComs} name='staffComs' id='staffComs' type="text" placeholder="Staff Coms" className="p-3 rounded-lg bg-base-300 w-full" />
                            </div>
                        } */}
                        <div>
                            <label htmlFor="otherLoans" className='text-sm mb-1 block'>What Other Loans Do They Have?</label>
                            <input onChange={e => handleChangedValue(e)} defaultValue={`${loanData?.otherLoans}`} name='otherLoans' type="text" placeholder="What Other Loans Do They Have?" className="p-3 rounded-lg bg-base-300 w-full" />
                        </div>
                    </div>
                    <div className='flex justify-between'>
                        {/* <div className='flex items-center mt-5'>
                            <input type="date" id="date" name="date" value={selectedDate} onChange={handleDateChange} className='input border-gray-400 border mr-3' />
                            <select id="time" name="time" className='input border-gray-400 border mr-3' value={selectedTime} onChange={handleTimeChange}>
                                <option value="">Select a time</option>
                                {generateTimeSlots().map((timeSlot, index) => (
                                    <option key={index} value={timeSlot}>{timeSlot}</option>
                                ))}
                            </select>
                            <button type='button' onClick={handleCallBackSubmit} className='btn bg-primary text-white'>Call Back</button>
                        </div> */}
                        {
                            loanData?.callBack && <div className='flex items-center mt-5'>
                                <p className='text-lg font-semibold'>Call Back Time: {new Date(loanData?.callBack?.date).toLocaleDateString('en-UK', { day: 'numeric', month: 'numeric', year: 'numeric', }) + ' ,' + loanData?.callBack?.time}</p>
                            </div>
                        }
                        {/* <div className='mt-4 text-right'>
                            <Link to={`/admin/dashboard/edit-loan/continue-to-pre-underwritting/${loanData._id}`} className='button !rounded mr-3'>New Pitch</Link>
                            {(adminData.role === 'super-admin' || user.email === 'smit@readysteadyfinance.co.uk' || user.email === 'millie@sedgefunding.co.uk') && <Link to={`/admin/dashboard/edit-loan/underwritting/${loanData._id}`} className='button !rounded mr-3'>Underwriting</Link>}
                            {
                                loading ? <button className="btn">
                                    <span className="loading loading-spinner"></span>
                                    loading
                                </button> : <div className='inline-block'><button type='button' onClick={newUpdateFunction} className='button !rounded mr-3'>submit stay</button><button type='button' onClick={newUpdateFunctionBack} className='button !rounded'>submit back</button></div>
                            }
                        </div> */}
                    </div>
                </form>
                {/* View All Details */}


                {/* <a target='_blank' href={process.env.REACT_APP_OPEN_BANKING+'/'+loanData._id} className='mt-10 text-white inline-block bg-primary p-3 rounded '>Open Banking</a><a target='_blank' href={process.env.REACT_APP_OPEN_BANKING+'/'+loanData._id} className='mt-10 ml-5 text-blue-500 inline-block'>Download</a> */}

                <div className='my-10 mr-10 flex gap-10 '>
                    <div className='min-w-[500px]'>
                        <div>
                            <table className="table p-5">
                                <thead>
                                    <tr className='border-0'>
                                        <th scope="col">
                                            <p>Required</p>
                                        </th>
                                        <th scope="col">
                                            <p>Title</p>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* bank6m */}
                                    <tr className='border-0 top-0'>
                                        <td className='text-left relative w-20'>
                                            <input
                                                className='absolute top-7'
                                                type="checkbox"
                                                name="bank6m"
                                                defaultChecked={loanData?.documents?.bank6m?.require || false}
                                                onChange={(e) => handleCheckboxChange(e, "bank6m", "require")}
                                            />
                                        </td>
                                        <td>
                                            <details className="collapse border border-base-300 bg-base-200 rounded-lg">
                                                <summary className='p-3 h-auto'>
                                                    <div className='font-[500] flex item-center justify-between'>
                                                        <span>6m Bank</span>
                                                        <input
                                                            type="checkbox"
                                                            name="bank6m"
                                                            defaultChecked={loanData?.documents?.bank6m?.done || false}
                                                            onChange={(e) => handleCheckboxChange(e, "bank6m", "done")}
                                                        />
                                                    </div>
                                                </summary>
                                                <div className="collapse-content">
                                                    <div className="relative">
                                                        <label
                                                            title="Click to upload"
                                                            htmlFor="bank6m"
                                                            className="cursor-pointer flex items-center gap-4 px-6 py-4 before:border-gray-400/60 group before:bg-darker hover:before:border-gray-500 before:bg-gray-100 before:border-gray-600 before:absolute before:inset-0 before:rounded-lg before:border before:border-dashed before:transition-transform before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95"
                                                        >
                                                            <div className="w-max relative">
                                                                <img
                                                                    className="w-12"
                                                                    src={uploadIcon}
                                                                    alt="file upload icon"
                                                                    width="512"
                                                                    height="512"
                                                                />
                                                            </div>
                                                            <div className="relative">
                                                                <span className="block text-base font-semibold relative text-primary">
                                                                    Upload a file
                                                                </span>
                                                                <span className="mt-0.5 block text-sm text-gray-500 dark:text-gray-400">Max 5 MB</span>
                                                            </div>
                                                        </label>
                                                        <input hidden type="file" name="bank6m" id="bank6m" multiple onChange={(e) => handleFileChange(e, "bank6m")} />
                                                    </div>

                                                    {uploadingLoader ? (<div className='text-center p-5 pb-0'>
                                                        <span className="loading loading-spinner loading-lg text-primary text-center "></span>
                                                    </div>) : (<div className="flex flex-col gap-2 mt-4">
                                                        {loanData?.documents?.bank6m?.attachment ? (
                                                            Array.isArray(loanData?.documents?.bank6m?.attachment) ? (
                                                                loanData?.documents?.bank6m?.attachment.map((item, i) => (typeof item === "string" ? <a href={item} className="pdf text-sm !bg-base-100 px-2 py-1 rounded-md" rel="noreferrer" target="_blank" key={i}>
                                                                    PDF {i + 1}
                                                                </a> : <a href={item.url} className="pdf text-sm !bg-base-100 px-2 py-1 rounded-md" rel="noreferrer" target="_blank" key={i}>
                                                                    {item?.name}
                                                                </a>))
                                                            ) : (
                                                                <a target='_blank' rel="noreferrer" href={loanData?.documents?.bank6m?.attachment} className='text-3xl'>
                                                                    <AiOutlineEye className='mr-auto' />
                                                                </a>
                                                            )
                                                        ) : (
                                                            // Render some default content if loanData?.documents?.bank6m?.attachment is undefined or null
                                                            <p>No attachment data available</p>
                                                        )}
                                                    </div>)}
                                                </div>
                                            </details>
                                        </td>
                                    </tr>

                                    {/* openBank */}
                                    <tr className='border-0 top-0'>
                                        <td className='text-left relative w-20'>
                                            <input
                                                className='absolute top-7'
                                                type="checkbox"
                                                name="openBank"
                                                defaultChecked={loanData?.documents?.openBank?.require || false}
                                                onChange={(e) => handleCheckboxChange(e, "openBank", "require")}
                                            />
                                        </td>
                                        <td>
                                            <details className="collapse border border-base-300 bg-base-200 rounded-lg">

                                                <summary className='p-3 h-auto'>
                                                    <div className='font-[500] flex item-center justify-between'>
                                                        <span>Open Bank</span>
                                                        <input
                                                            type="checkbox"
                                                            name="openBank"
                                                            defaultChecked={loanData?.documents?.openBank?.done || false}
                                                            onChange={(e) => handleCheckboxChange(e, "openBank", "done")}
                                                        />
                                                    </div>
                                                </summary>
                                                <div className="collapse-content">
                                                    <div className="relative">
                                                        <label
                                                            title="Click to upload"
                                                            htmlFor="openBank"
                                                            className="cursor-pointer flex items-center gap-4 px-6 py-4 before:border-gray-400/60 group before:bg-darker hover:before:border-gray-500 before:bg-gray-100 before:border-gray-600 before:absolute before:inset-0 before:rounded-lg before:border before:border-dashed before:transition-transform before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95"
                                                        >
                                                            <div className="w-max relative">
                                                                <img
                                                                    className="w-12"
                                                                    src={uploadIcon}
                                                                    alt="file upload icon"
                                                                    width="512"
                                                                    height="512"
                                                                />
                                                            </div>
                                                            <div className="relative">
                                                                <span className="block text-base font-semibold relative text-primary">
                                                                    Upload a file
                                                                </span>
                                                                <span className="mt-0.5 block text-sm text-gray-500 dark:text-gray-400">Max 5 MB</span>
                                                            </div>
                                                        </label>
                                                        <input hidden type="file" name="openBank" id="openBank" multiple onChange={(e) => handleFileChange(e, "openBank")} />
                                                    </div>

                                                    <button onClick={copyToClipboard} className='btn bg-primary hover:bg-black mt-5 w-full btn-sm text-white text-sm'>
                                                        Copy Open Banking Link Here
                                                    </button>

                                                    <div>
                                                        {openBankingRequisition && !obloading && (
                                                            <div className='mt-5'>
                                                                <select id='openBankingAccountID' className='bg-base-300 input input-sm' defaultValue={openBankingRequisition.accounts[0]}>
                                                                    {/* {openBankingRequisition.accounts.map((item, i) => (
                                                                        <option key={i}>{item}</option>
                                                                    ))} */}
                                                                    {accountData.map((account) => (
                                                                        <option key={account.id} value={account.id}>
                                                                            {account.iban}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                <button onClick={exportData} className='btn btn-sm bg-primary hover:bg-black text-white ml-3'>Downoad</button>
                                                            </div>
                                                        )}
                                                    </div>

                                                    {uploadingLoader ? (<div className='text-center p-5 pb-0'>
                                                        <span className="loading loading-spinner loading-lg text-primary text-center "></span>
                                                    </div>) : (<div className="flex flex-col gap-2 mt-4">
                                                        {loanData?.documents?.openBank?.attachment ? (
                                                            Array.isArray(loanData?.documents?.openBank?.attachment) ? (
                                                                loanData?.documents?.openBank?.attachment.map((item, i) => (typeof item === "string" ? <a href={item} className="pdf text-sm !bg-base-100 px-2 py-1 rounded-md" rel="noreferrer" target="_blank" key={i}>
                                                                    PDF {i + 1}
                                                                </a> : <a href={item.url} className="pdf text-sm !bg-base-100 px-2 py-1 rounded-md" rel="noreferrer" target="_blank" key={i}>
                                                                    {item?.name}
                                                                </a>))
                                                            ) : (
                                                                <a target='_blank' rel="noreferrer" href={loanData?.documents?.openBank?.attachment} className='text-3xl'>
                                                                    <AiOutlineEye className='mr-auto' />
                                                                </a>
                                                            )
                                                        ) : (
                                                            // Render some default content if loanData?.documents?.openBank?.attachment is undefined or null
                                                            <p>No attachment data available</p>
                                                        )}
                                                    </div>)}
                                                </div>
                                            </details>
                                        </td>
                                    </tr>

                                    {/* ID */}
                                    <tr className='border-0 top-0'>
                                        <td className='text-left relative w-20'>
                                            <input
                                                className='absolute top-7'
                                                type="checkbox"
                                                name="id"
                                                defaultChecked={loanData?.documents?.id?.require || false}
                                                onChange={(e) => handleCheckboxChange(e, "id", "require")}
                                            />
                                        </td>
                                        <td>
                                            <details className="collapse border border-base-300 bg-base-200 rounded-lg">

                                                <summary className='p-3 h-auto'>
                                                    <div className='font-[500] flex item-center justify-between'>
                                                        <span>ID</span>
                                                        <input
                                                            type="checkbox"
                                                            name="id"
                                                            defaultChecked={loanData?.documents?.id?.done || false}
                                                            onChange={(e) => handleCheckboxChange(e, "id", "done")}
                                                        />
                                                    </div>
                                                </summary>
                                                <div className="collapse-content">
                                                    <div className="relative">
                                                        <label
                                                            title="Click to upload"
                                                            htmlFor="id"
                                                            className="cursor-pointer flex items-center gap-4 px-6 py-4 before:border-gray-400/60 group before:bg-darker hover:before:border-gray-500 before:bg-gray-100 before:border-gray-600 before:absolute before:inset-0 before:rounded-lg before:border before:border-dashed before:transition-transform before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95"
                                                        >
                                                            <div className="w-max relative">
                                                                <img
                                                                    className="w-12"
                                                                    src={uploadIcon}
                                                                    alt="file upload icon"
                                                                    width="512"
                                                                    height="512"
                                                                />
                                                            </div>
                                                            <div className="relative">
                                                                <span className="block text-base font-semibold relative text-primary">
                                                                    Upload a file
                                                                </span>
                                                                <span className="mt-0.5 block text-sm text-gray-500 dark:text-gray-400">Max 5 MB</span>
                                                            </div>
                                                        </label>
                                                        <input hidden type="file" name="id" id="id" multiple onChange={(e) => handleFileChange(e, "id")} />
                                                    </div>

                                                    {uploadingLoader ? (<div className='text-center p-5 pb-0'>
                                                        <span className="loading loading-spinner loading-lg text-primary text-center "></span>
                                                    </div>) : (<div className="flex flex-col gap-2 mt-4">
                                                        {loanData?.documents?.id?.attachment ? (
                                                            Array.isArray(loanData?.documents?.id?.attachment) ? (
                                                                loanData?.documents?.id?.attachment.map((item, i) => (typeof item === "string" ? <a href={item} className="pdf text-sm !bg-base-100 px-2 py-1 rounded-md" rel="noreferrer" target="_blank" key={i}>
                                                                    PDF {i + 1}
                                                                </a> : <a href={item.url} className="pdf text-sm !bg-base-100 px-2 py-1 rounded-md" rel="noreferrer" target="_blank" key={i}>
                                                                    {item?.name}
                                                                </a>))
                                                            ) : (
                                                                <a target='_blank' rel="noreferrer" href={loanData?.documents?.id?.attachment} className='text-3xl'>
                                                                    <AiOutlineEye className='mr-auto' />
                                                                </a>
                                                            )
                                                        ) : (
                                                            // Render some default content if loanData?.documents?.id?.attachment is undefined or null
                                                            <p>No attachment data available</p>
                                                        )}
                                                    </div>)}
                                                </div>
                                            </details>
                                        </td>
                                    </tr>




                                    {/* proofeOfAddress */}
                                    <tr className='border-0 top-0'>
                                        <td className='text-left relative w-20'>
                                            <input
                                                className='absolute top-7'
                                                type="checkbox"
                                                name="proofeOfAddress"
                                                defaultChecked={loanData?.documents?.proofeOfAddress?.require || false}
                                                onChange={(e) => handleCheckboxChange(e, "proofeOfAddress", "require")}
                                            />
                                        </td>
                                        <td>
                                            <details className="collapse border border-base-300 bg-base-200 rounded-lg">

                                                <summary className='p-3 h-auto'>
                                                    <div className='font-[500] flex item-center justify-between'>
                                                        <span>Proof Of Address</span>
                                                        <input
                                                            type="checkbox"
                                                            name="proofeOfAddress"
                                                            defaultChecked={loanData?.documents?.proofeOfAddress?.done || false}
                                                            onChange={(e) => handleCheckboxChange(e, "proofeOfAddress", "done")}
                                                        />
                                                    </div>
                                                </summary>
                                                <div className="collapse-content">
                                                    <div className="relative">
                                                        <label
                                                            title="Click to upload"
                                                            htmlFor="proofeOfAddress"
                                                            className="cursor-pointer flex items-center gap-4 px-6 py-4 before:border-gray-400/60 group before:bg-darker hover:before:border-gray-500 before:bg-gray-100 before:border-gray-600 before:absolute before:inset-0 before:rounded-lg before:border before:border-dashed before:transition-transform before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95"
                                                        >
                                                            <div className="w-max relative">
                                                                <img
                                                                    className="w-12"
                                                                    src={uploadIcon}
                                                                    alt="file upload icon"
                                                                    width="512"
                                                                    height="512"
                                                                />
                                                            </div>
                                                            <div className="relative">
                                                                <span className="block text-base font-semibold relative text-primary">
                                                                    Upload a file
                                                                </span>
                                                                <span className="mt-0.5 block text-sm text-gray-500 dark:text-gray-400">Max 5 MB</span>
                                                            </div>
                                                        </label>
                                                        <input hidden type="file" name="proofeOfAddress" id="proofeOfAddress" multiple onChange={(e) => handleFileChange(e, "proofeOfAddress")} />
                                                    </div>

                                                    {uploadingLoader ? (<div className='text-center p-5 pb-0'>
                                                        <span className="loading loading-spinner loading-lg text-primary text-center "></span>
                                                    </div>) : (<div className="flex flex-col gap-2 mt-4">
                                                        {loanData?.documents?.proofeOfAddress?.attachment ? (
                                                            Array.isArray(loanData?.documents?.proofeOfAddress?.attachment) ? (
                                                                loanData?.documents?.proofeOfAddress?.attachment.map((item, i) => (typeof item === "string" ? <a href={item} className="pdf text-sm !bg-base-100 px-2 py-1 rounded-md" rel="noreferrer" target="_blank" key={i}>
                                                                    PDF {i + 1}
                                                                </a> : <a href={item.url} className="pdf text-sm !bg-base-100 px-2 py-1 rounded-md" rel="noreferrer" target="_blank" key={i}>
                                                                    {item?.name}
                                                                </a>))
                                                            ) : (
                                                                <a target='_blank' rel="noreferrer" href={loanData?.documents?.proofeOfAddress?.attachment} className='text-3xl'>
                                                                    <AiOutlineEye className='mr-auto' />
                                                                </a>
                                                            )
                                                        ) : (
                                                            // Render some default content if loanData?.documents?.proofeOfAddress?.attachment is undefined or null
                                                            <p>No attachment data available</p>
                                                        )}
                                                    </div>)}
                                                </div>
                                            </details>
                                        </td>
                                    </tr>

                                    {/* eya */}
                                    <tr className='border-0 top-0'>
                                        <td className='text-left relative w-20'>
                                            <input
                                                className='absolute top-7'
                                                type="checkbox"
                                                name="eya"
                                                defaultChecked={loanData?.documents?.eya?.require || false}
                                                onChange={(e) => handleCheckboxChange(e, "eya", "require")}
                                            />
                                        </td>
                                        <td>
                                            <details className="collapse border border-base-300 bg-base-200 rounded-lg">

                                                <summary className='p-3 h-auto'>
                                                    <div className='font-[500] flex item-center justify-between'>
                                                        <span>EYA</span>
                                                        <input
                                                            type="checkbox"
                                                            name="eya"
                                                            defaultChecked={loanData?.documents?.eya?.done || false}
                                                            onChange={(e) => handleCheckboxChange(e, "eya", "done")}
                                                        />
                                                    </div>
                                                </summary>
                                                <div className="collapse-content">
                                                    <div className="relative">
                                                        <label
                                                            title="Click to upload"
                                                            htmlFor="eya"
                                                            className="cursor-pointer flex items-center gap-4 px-6 py-4 before:border-gray-400/60 group before:bg-darker hover:before:border-gray-500 before:bg-gray-100 before:border-gray-600 before:absolute before:inset-0 before:rounded-lg before:border before:border-dashed before:transition-transform before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95"
                                                        >
                                                            <div className="w-max relative">
                                                                <img
                                                                    className="w-12"
                                                                    src={uploadIcon}
                                                                    alt="file upload icon"
                                                                    width="512"
                                                                    height="512"
                                                                />
                                                            </div>
                                                            <div className="relative">
                                                                <span className="block text-base font-semibold relative text-primary">
                                                                    Upload a file
                                                                </span>
                                                                <span className="mt-0.5 block text-sm text-gray-500 dark:text-gray-400">Max 5 MB</span>
                                                            </div>
                                                        </label>
                                                        <input hidden type="file" name="eya" id="eya" multiple onChange={(e) => handleFileChange(e, "eya")} />
                                                    </div>

                                                    {uploadingLoader ? (<div className='text-center p-5 pb-0'>
                                                        <span className="loading loading-spinner loading-lg text-primary text-center "></span>
                                                    </div>) : (<div className="flex flex-col gap-2 mt-4">
                                                        {loanData?.documents?.eya?.attachment ? (
                                                            Array.isArray(loanData?.documents?.eya?.attachment) ? (
                                                                loanData?.documents?.eya?.attachment.map((item, i) => (typeof item === "string" ? <a href={item} className="pdf text-sm !bg-base-100 px-2 py-1 rounded-md" rel="noreferrer" target="_blank" key={i}>
                                                                    PDF {i + 1}
                                                                </a> : <a href={item.url} className="pdf text-sm !bg-base-100 px-2 py-1 rounded-md" rel="noreferrer" target="_blank" key={i}>
                                                                    {item?.name}
                                                                </a>))
                                                            ) : (
                                                                <a target='_blank' rel="noreferrer" href={loanData?.documents?.eya?.attachment} className='text-3xl'>
                                                                    <AiOutlineEye className='mr-auto' />
                                                                </a>
                                                            )
                                                        ) : (
                                                            // Render some default content if loanData?.documents?.eya?.attachment is undefined or null
                                                            <p>No attachment data available</p>
                                                        )}
                                                    </div>)}
                                                </div>
                                            </details>
                                        </td>
                                    </tr>

                                    {/* Other Bank Details */}
                                    <tr className='border-0 top-0'>
                                        <td className='text-left relative w-20'>
                                            <input
                                                className='absolute top-7'
                                                type="checkbox"
                                                name="other"
                                                defaultChecked={loanData?.documents?.other?.require || false}
                                                onChange={(e) => handleCheckboxChange(e, "other", "require")}
                                            />
                                        </td>
                                        <td>
                                            <details className="collapse border border-base-300 bg-base-200 rounded-lg">

                                                <summary className='p-3 h-auto'>
                                                    <div className='font-[500] flex item-center justify-between'>
                                                        <span>Other Documents</span>
                                                        <input
                                                            type="checkbox"
                                                            name="other"
                                                            defaultChecked={loanData?.documents?.other?.done || false}
                                                            onChange={(e) => handleCheckboxChange(e, "other", "done")}
                                                        />
                                                    </div>
                                                </summary>
                                                <div className="collapse-content">
                                                    <div className="relative">
                                                        <label
                                                            title="Click to upload"
                                                            htmlFor="other"
                                                            className="cursor-pointer flex items-center gap-4 px-6 py-4 before:border-gray-400/60 group before:bg-darker hover:before:border-gray-500 before:bg-gray-100 before:border-gray-600 before:absolute before:inset-0 before:rounded-lg before:border before:border-dashed before:transition-transform before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95"
                                                        >
                                                            <div className="w-max relative">
                                                                <img
                                                                    className="w-12"
                                                                    src={uploadIcon}
                                                                    alt="file upload icon"
                                                                    width="512"
                                                                    height="512"
                                                                />
                                                            </div>
                                                            <div className="relative">
                                                                <span className="block text-base font-semibold relative text-primary">
                                                                    Upload a file
                                                                </span>
                                                                <span className="mt-0.5 block text-sm text-gray-500 dark:text-gray-400">Max 5 MB</span>
                                                            </div>
                                                        </label>
                                                        <input hidden type="file" name="other" id="other" multiple onChange={(e) => handleFileChange(e, "other")} />
                                                    </div>

                                                    {uploadingLoader ? (<div className='text-center p-5 pb-0'>
                                                        <span className="loading loading-spinner loading-lg text-primary text-center "></span>
                                                    </div>) : (<div className="flex flex-col gap-2 mt-4">
                                                        {loanData?.documents?.other?.attachment ? (
                                                            Array.isArray(loanData?.documents?.other?.attachment) ? (
                                                                loanData?.documents?.other?.attachment.map((item, i) => (typeof item === "string" ? <a href={item} className="pdf text-sm !bg-base-100 px-2 py-1 rounded-md" rel="noreferrer" target="_blank" key={i}>
                                                                    PDF {i + 1}
                                                                </a> : <a href={item.url} className="pdf text-sm !bg-base-100 px-2 py-1 rounded-md" rel="noreferrer" target="_blank" key={i}>
                                                                    {item?.name}
                                                                </a>))
                                                            ) : (
                                                                <a target='_blank' rel="noreferrer" href={loanData?.documents?.other?.attachment} className='text-3xl'>
                                                                    <AiOutlineEye className='mr-auto' />
                                                                </a>
                                                            )
                                                        ) : (
                                                            <p>No attachment data available</p>
                                                        )}
                                                    </div>)}
                                                </div>
                                            </details>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='grow'>
                        <h2 className='font-semibold text-2xl'>Team Notes:</h2>
                        {/* <div className='flex gap-2 mt-2'>
                            <input
                                name='teamNotes'
                                id='teamNotes'
                                type="text"
                                placeholder="Team Notes"
                                className="p-3 rounded-lg bg-base-300 w-full"
                                value={teamNote}
                                onChange={(e) => setTeamNote(e.target.value)}
                                required
                            />
                            <button className='button' onClick={handleNoteSubmit}>Submit</button>
                        </div> */}
                        <div className="mt-2 h-[355px] overflow-y-scroll">
                            {loanData.teamNotes && loanData.teamNotes.length > 0 ? (
                                loanData.teamNotes.slice().reverse().map((note, index) => (
                                    <div key={index}>
                                        {typeof note === 'object' ? (
                                            <div className='mb-3 bg-base-200 p-3 rounded-xl' style={{ boxShadow: "rgba(0, 0, 0, 0.05) 0px 20px 27px 0px" }}>
                                                {note.name}
                                                <br />
                                                {note.note}
                                            </div>
                                        ) : (
                                            <div className='mb-3 bg-base-200 p-3 rounded-xl' style={{ boxShadow: "rgba(0, 0, 0, 0.05) 0px 20px 27px 0px" }}>{note}</div>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <p>No new notes</p>
                            )}
                        </div>

                    </div>
                </div>
            </div>

            {/* transactions={trans} */}
            {/* {loanData['business-name'] === "Steav testing MCA" && <div>
                <PDFViewer width={500} height={705} showToolbar={false}>
                    <BankStateMent transactions={trans} />
                </PDFViewer>
                <p>You can download your invoice now: <PDFDownloadLink fileName={loanData['business-name'] || 'invoice'} document={<BankStateMent transactions={trans} />} className='text-primary'>Download Invoice</PDFDownloadLink></p>
            </div>} */}
        </div >
    )
}

export default EditBusinessLoan
