import React, { useContext, useState } from 'react'
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { storage } from '../../firebase/firebase.config';
import { ref, uploadBytes } from 'firebase/storage';
import { AiOutlineArrowLeft } from 'react-icons/ai';

const AddSingleDeal = () => {
    const statusArray = ['Initial Review', 'Golden Data', 'The Great Takeover', 'Pre-Underwriting', 'Pre-Offer', 'Underwriting', 'Extra docs ob', 'Awaiting Offer', 'Offer', 'Contract Sent', 'Awaiting Funding', 'Last Chance', 'Cancelled', 'Cancelled for ever']
    const { user, adminData } = useContext(AuthContext);
    const navigate = useNavigate();
    const [allpdf, setAllpdf] = useState([]);
    const [loading, setLoading] = useState(false);

    // useState for input field
    const [status, setStatus] = useState('Initial Review');
    const [lender, setLender] = useState(null);
    const [fundingManager, setFundingManager] = useState(null);
    const [businessName, setBusinessName] = useState(null);
    const [businessType, setBusinessType] = useState(null);
    const [businessAddress, setBusinessAddress] = useState(null);
    const [businessOwnerName, setBusinessOwnerName] = useState(null);
    const [birthDate, setBirthDate] = useState(null);
    const [homeAddress, setHomeAddress] = useState(null);
    const [email, setEmail] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [requiredAmount, setRequiredAmount] = useState(null);


    const handleWheel = (e) => {
        e.preventDefault(); // Prevent scrolling
        e.target.blur();    // Remove focus to disable scroll change
    };

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            e.preventDefault(); // Prevent arrow key changes
        }
    };

    const addNewDeal = () => {
        setLoading(true);
        const data = {
            "lenders": {
                "New Deal": {
                    "status": status,
                    "show-in-kanban": "Yes"
                }
            },
            "funding-manager": fundingManager || adminData.name,
            "business-name": businessName,
            "business-type": businessType,
            "business-address": businessAddress,
            "business-owner-name": businessOwnerName,
            "birth-date": birthDate,
            "home-address": homeAddress,
            "business-email": email,
            "business-phone": phoneNumber,
            "required-amount": requiredAmount,
            "metadata": {
                "createdBy": user?.email,
                "createdAt": new Date().toISOString()
            },
            country: '£',
            email: user?.email
        };
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v3/new-deal`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(result => {
                console.log(result);
                setLoading(false);
                navigate(-1);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }
    return (
        <div>
            <button onClick={() => navigate(-1)} className='btn mt-5 bg-white text-gray-500' style={{ "boxShadow": "rgba(0, 0, 0, 0.05) 0px 20px 27px 0px" }}><AiOutlineArrowLeft />Go Back</button>
            <div className='grid grid-cols-3 gap-3 mt-10'>
                <div>
                    <label htmlFor="status" className='text-sm mb-1 block'>Status:</label>
                    <select onChange={(e) => setStatus(e.target.value)} defaultValue={statusArray[0]} id='status' className="p-3 rounded-lg bg-base-300 w-full">
                        {statusArray.map((status, index) => (
                            <option value={status} key={index}>{status}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label htmlFor="fundingManager" className='text-sm mb-1 block'>Funding Manager:</label>
                    <select onChange={(e) => setFundingManager(e.target.value)} name='funding-manager' id='fundingManager' defaultValue={adminData.name} className="p-3 rounded-lg bg-base-300 w-full">
                        <option value={adminData.name}>{adminData.name}</option>
                        <option value="Jon">Jon</option>
                        <option value="Smit">Smit</option>
                        <option value="Mark">Mark</option>
                        <option value="Don">Don</option>
                        <option value="Becher">Becher</option>
                        <option value="Carl">Carl</option>
                        <option value="Janne">Janne</option>
                        <option value="Khymir">Khymir</option>
                        <option value="Wayne">Wayne</option>
                        <option value="Peter">Peter</option>
                        <option value="Millie">Millie</option>
                        {
							(adminData?.role === "super-admin" || user.email === "millie@sedgefunding.co.uk") && <option value="Max">Max</option>
						}
                        {
							(adminData?.role === "super-admin" || user.email === "millie@sedgefunding.co.uk") && <option value="Tan">Tan</option>
						}
                        {
							(adminData?.role === "super-admin" || user.email === "millie@sedgefunding.co.uk") && <option value="Roger">Roger</option>
						}
                    </select>
                </div>
                <div>
                    <label htmlFor="businessName" className='text-gray-500 text-sm mb-1 block'>Business Name:</label>
                    <input onChange={(e) => setBusinessName(e.target.value)} name='businessName' id='businessName' type="text" placeholder="Exe. Sedge Funding" className="p-3 rounded-lg bg-base-300 w-full" required />
                </div>
                <div>
                    <label htmlFor="businessType" className='text-gray-500 text-sm mb-1 block'>Business Type:</label>
                    <select onChange={(e) => setBusinessType(e.target.value)} name='businessType' id='businessType' className="p-3 rounded-lg bg-base-300 w-full" required>
                        <option value="" defaultValue>Company Type</option>
                        <option value="Ltd">Ltd</option>
                        <option value="PLC">PLC</option>
                        <option value="LLP">LLP</option>
                        <option value="Sole Trader">Sole Trader</option>
                        <option value="Partnership">Partnership</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="businessAddress" className='text-gray-500 text-sm mb-1 block'>Business Address:</label>
                    <input onChange={(e) => setBusinessAddress(e.target.value)} name='businessAddress' id='businessAddress' type="text" placeholder="Business Address" className="p-3 rounded-lg bg-base-300 w-full" required />
                </div>
                <div>
                    <label htmlFor="contactName" className='text-gray-500 text-sm mb-1 block'>Business Contact Name (Owner):</label>
                    <input onChange={(e) => setBusinessOwnerName(e.target.value)} name='contactName' type="text" placeholder="Contact Name" className="p-3 rounded-lg bg-base-300 w-full" required />
                </div>
                <div>
                    <label htmlFor="dateOfBirth" className=' text-sm mb-1 block'>Date Of Birth:</label>
                    <input onChange={(e) => setBirthDate(e.target.value)} name='date-of-birth' type="date" className="p-3 rounded-lg bg-base-300 w-full" required />
                </div>
                <div>
                    <label htmlFor="home-address" className=' text-sm mb-1 block'>Home Address:</label>
                    <input onChange={(e) => setHomeAddress(e.target.value)} name='home-address' id='home-address' type="text" placeholder="Home Address" className="p-3 rounded-lg bg-base-300 w-full" required />
                </div>
                <div>
                    <label htmlFor="email" className='text-gray-500 text-sm mb-1 block'>Email:</label>
                    <input onChange={(e) => setEmail(e.target.value)} name='email' id='email' type="text" placeholder="Email" className="p-3 rounded-lg bg-base-300 w-full" required />
                </div>
                <div>
                    <label htmlFor="phone" className='text-gray-500 text-sm mb-1 block'>Phone Number:</label>
                    <input onChange={(e) => setPhoneNumber(e.target.value)} name='phone' id='phone' type="text" placeholder="Phone Number" className="p-3 rounded-lg bg-base-300 w-full" required />
                </div>
                <div>
                    <label htmlFor="requiredAmount" className=' text-sm mb-1 block'>Required Amount:</label>
                    <input onChange={(e) => setRequiredAmount(e.target.value)} onWheel={handleWheel} onKeyDown={handleKeyDown} name='required-amount' id='requiredAmount' type="number" placeholder="Required Amount" className="p-3 rounded-lg bg-base-300 w-full" required />
                </div>
            </div>
            <div className='mt-4 text-right'>
                {
                    loading ? <button className="btn">
                        <span className="loading loading-spinner"></span>
                        loading
                    </button> : <button onClick={addNewDeal} type='submit' className='button rounded'>submit</button>
                }
            </div>

        </div>
    )
}

export default AddSingleDeal