import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { HiTrash } from "react-icons/hi";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import EditBook from "../../components/EditBook/EditBook";
import ViewDeal from "../../components/ViewDeal/ViewDeal";
import axios from "axios";
import { IoCloseOutline } from "react-icons/io5";
import { FaPlus } from "react-icons/fa6";
import { PacmanLoader } from "react-spinners";

const Book = () => {
	const serverURL = `${process.env.REACT_APP_SERVER_URL}`;
	// const serverURL = "http://localhost:5000";
	const { user, loading, country, setCountry, adminData } = useContext(AuthContext);
	const [dealList, setDealList] = useState([]);
	const [editBook, setEditBook] = useState({});
	const [totallRepayment, setTotallRepayment] = useState(0);
	const [refresh, setRefresh] = useState(true);
	const [viewDeal, setViewDeal] = useState({});
	const [bookFilter, setBookFilter] = useState({});
	const [sortDealbyData, setSortDealbyData] = useState(false);
	const [sortByLoanAmount, setSortByLoanAmount] = useState(false);
	const [isLoading, setIsloading] = useState(false);

	let initialTotallRepayment = 0;

	const submitDeals = (e) => {
		e.preventDefault();
		const dialog = document.getElementById("deal");
		const loanData = {
			funder: e?.target?.funder?.value,
			companyname: e?.target?.companyname?.value,
			loanamount: e?.target?.loanamount?.value,
			repayment: e?.target?.repayment?.value,
			factor: e?.target?.factor?.value,
			comrate: e?.target?.comrate?.value,
			comamo: e?.target?.comamo?.value,
			status: e?.target?.status?.value,
			closer: e?.target?.closer?.value,
			note: e?.target?.note?.value,
			user: user.email,
			createdDate: e?.target?.date?.value,
		};
		fetch(`${process.env.REACT_APP_SERVER_URL}/submit-deals`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(loanData),
		})
			.then((response) => response.json())
			.then((result) => {
				e.target.reset();
				loanData._id = result?.insertedId;
				setDealList([...dealList, loanData]);
				dialog.close();
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	};

	const closeModal = (type) => {
		const dialog = document.getElementById(type);
		dialog.close();
	};

	const openDeal = () => {
		window.deal.showModal();
	};

	const handleEditBook = (item) => {
		window.editBook.showModal();
		setEditBook(item);
	};

	const deleteRequest = async (id) => {
		try {
			await fetch(`${process.env.REACT_APP_SERVER_URL}/book-request/${id}`, {
				method: "DELETE",
			});
			setRefresh(!refresh); // Refresh the loan requests after deletion
		} catch (error) {
			console.error("Error:", error);
		}
	};

	const searchByName = (e) => {
		const searchName = e.target.value;
		const url = `${process.env.REACT_APP_SERVER_URL}/deal/search?name=${encodeURIComponent(searchName)}`;

		fetch(url)
			.then((response) => response.json())
			.then((data) => {
				setDealList(data);
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	};

	const handleViewRequest = (item) => {
		setViewDeal(item);
		window.viewDeal.showModal();
	};

	const searchByFunder = (e) => {
		setDealList([]);
		const searchName = e.target.value;
		const url = `${process.env.REACT_APP_SERVER_URL}/deal/funder?status=${encodeURIComponent(searchName)}`;

		fetch(url)
			.then((response) => response.json())
			.then((data) => {
				setDealList(data);
			})
			.catch((error) => {
				console.error("Error:", error);
				// Handle the error or display an error message
			});
	};

	const searchByStatus = (e) => {
		setDealList([]);
		const searchName = e.target.value;
		const url = `${process.env.REACT_APP_SERVER_URL}/deal/status?status=${encodeURIComponent(searchName)}`;

		fetch(url)
			.then((response) => response.json())
			.then((data) => {
				setDealList(data);
			})
			.catch((error) => {
				console.error("Error:", error);
				// Handle the error or display an error message
			});
	};

	useEffect(() => {
		setIsloading(true);
		const userRole = adminData?.role;

		if (userRole === "super-admin") {
			axios
				.post(
					`${serverURL}/super-admin/deals/GETALL`,
					{
						email: user.email,
						searchQuery: bookFilter, // Ensure the searchQuery object is structured correctly
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${localStorage.getItem("token")}`,
						},
					}
				)
				.then((response) => {
					return response.data;
				})
				.then((data) => {
					// Call a function to handle the loan data
					setDealList(data);
					// console.log(data);
					let tempTotall = 0;
					// eslint-disable-next-line array-callback-return
					data.map((item) => {
						tempTotall = tempTotall + parseInt(item.repayment);
					});
					setTotallRepayment(tempTotall);
					setIsloading(false);
				})
				.catch((error) => {
					console.error("Error:", error);
					setIsloading(false);
				});
		}
	}, [refresh, bookFilter]);

	const sortDataByDate = () => {
		const sortedData = [...dealList].sort((a, b) => {
			const dateA = new Date(a.createdDate);
			const dateB = new Date(b.createdDate);
			return dateA - dateB;
		});
		setSortDealbyData(false);
		setDealList(sortedData);
	};

	const sortDataByDateReverse = () => {
		const sortedData = [...dealList].sort((a, b) => {
			const dateA = new Date(a.createdDate);
			const dateB = new Date(b.createdDate);
			return dateB - dateA;
		});
		setSortDealbyData(true);
		setDealList(sortedData);
	};
	const sortDataByLoanAmount = () => {
		setSortByLoanAmount(true);
		const sortedData = [...dealList].sort((a, b) => {
			const dateA = a["loanamount"] || 0;
			const dateB = b["loanamount"] || 0;
			return parseInt(dateA) - parseInt(dateB);
		});
		setDealList(sortedData);
	};
	const sortDataByLoanAmountReverse = () => {
		setSortByLoanAmount(false);
		const sortedData = [...dealList].sort((a, b) => {
			const dateA = a["loanamount"] || 0;
			const dateB = b["loanamount"] || 0;
			return parseInt(dateB) - parseInt(dateA);
		});
		setDealList(sortedData);
	};

	const searchResult = (e) => {
		e.preventDefault();
		let tempObject = {};
		if (e.target["companyname"].value && e.target["companyname"].value !== null) {
			tempObject = { ...tempObject, companyname: e.target["companyname"].value };
		}
		if (e.target["funder"].value && e.target["funder"].value !== "all") {
			tempObject = { ...tempObject, funder: e.target["funder"].value };
		}
		if (adminData.role === "super-admin" && e.target["status"].value && e.target["status"].value !== "all") {
			tempObject = { ...tempObject, status: e.target["status"].value };
		}
		if (adminData.role === "super-admin" && e.target["funding-manager"].value && e.target["funding-manager"].value !== "Pending") {
			tempObject = { ...tempObject, closer: e.target["funding-manager"].value };
		}
		setBookFilter(tempObject);
	};

	const rows = ["Date Funded", "Funder", "Company Name", "Loan Amount", "Repayment", "Factor", "Com Rate", "Com Amo", "Status", "Closer", "Action"];

	if (isLoading) {
		return (
			<div className="text-4xl mt-[35vh] flex justify-center items-center">
				<PacmanLoader color="#e63a27" />
			</div>
		);
	}

	return (
		<div className="pb-20">
			<form onSubmit={(e) => searchResult(e)} className="flex justify-between items-center sticky top-[60px] bg-base-100 z-10 py-5">
				<div className="flex items-center justify-center gap-2">
					<input name="companyname" type="text" placeholder="Search by Company Name" className="input h-10 input-bordered  text-sm" />
					<select name="funder" className="input h-10 text-sm input-bordered">
						<option value="all" selected>
							All Lenders
						</option>
						<option value="Liberis">Liberis</option>
						<option value="Youlend">Youlend</option>
						<option value="365 Finance">365 Finance</option>
						<option value="Sedge">Sedge</option>
						<option value="Capify">Capify</option>
						<option value="Playter">Playter</option>
						<option value="Penny">Penny</option>
						<option value="IWOCA">IWOCA</option>
						<option value="Swoop">Swoop</option>
						<option value="Funding Alt">Funding Alt</option>
						<option value="Maxcap">Maxcap</option>
						<option value="Swiftfund">Swiftfund</option>
						<option value="Funding Circle">Funding Circle</option>
						<option value="My Cash Line">My Cash Line</option>
						<option value="Bizcap">Bizcap</option>
						<option value="Seneca">Seneca</option>
						<option value="Sapi">Sapi</option>
					</select>

					<select name="funding-manager" id="searchFilterManager" className="input h-10 text-sm input-bordered">
						<option value="Pending">F. Manager</option>
						<option value="Wayne">Wayne</option>
						<option value="Peter">Peter</option>
						<option value="Smit">Smit</option>
						<option value="Jon">Jon</option>
						<option value="Millie">Millie</option>
						<option value="Mark">Mark</option>
						<option value="Becher">Becher</option>
						<option value="Don">Don</option>
						<option value="Carl">Carl</option>
						<option value="Janne">Janne</option>
						<option value="Khymir">Khymir</option>
						<option value="Chris">Chris</option>
						<option value="Maria">Maria</option>
					</select>
					<select name="status" className="input h-10 text-sm input-bordered">
						<option value="all" selected>
							All Status
						</option>
						<option value="TBC">TBC</option>
						<option value="Funded">Funded</option>
						<option value="Renewed">Renewed</option>
						<option value="Dead">Dead</option>
						<option value="Moved">Moved</option>
						<option value="Ready">Ready</option>
					</select>
					<button type="submit" className="bg-primary text-white py-2 px-4 text-sm rounded-lg">
						Search
					</button>
					<button type="button" onClick={() => setBookFilter({})} className="text-xl hover:bg-base-100 p-3 rounded-lg">
						<IoCloseOutline />
					</button>
				</div>

				<h2 className="font-semibold text-3xl">Repayments: £{parseInt(totallRepayment).toString() !== "NaN" ? parseInt(totallRepayment).toLocaleString("en-US", { style: "decimal", useGrouping: true }) : "0"} </h2>
				<button type="button" onClick={openDeal} className="bg-primary text-white py-2 px-4 rounded-lg">
					<span className="flex items-center gap-3 text-sm">
						Add Deal <FaPlus />
					</span>
				</button>
			</form>
			<div className="rounded-lg bg-base-200">
				<div className="overflow-x-auto p-3">
					<table className="table">
						{/* head */}
						<thead>
							<tr>
								{rows.map((item, i) => (
									<th key={i} scope="col">
										{item === "Date Funded" && item !== "Loan Amount" ? (
											sortDealbyData ? (
												<p className="flex justify-start items-center gap-2">
													{item}{" "}
													<span onClick={sortDataByDate} className="cursor-pointer">
														<BiSolidDownArrow />
													</span>
												</p>
											) : (
												<p className="flex justify-start items-center gap-2">
													{item}{" "}
													<span onClick={sortDataByDateReverse} className="cursor-pointer">
														<BiSolidUpArrow />
													</span>
												</p>
											)
										) : (
											item !== "CREATED" && item !== "Loan Amount" && item
										)}
										{item === "Loan Amount" &&
											(sortByLoanAmount === false ? (
												<p className="flex justify-start items-center gap-2">
													{item}{" "}
													<span onClick={sortDataByLoanAmount} className="cursor-pointer">
														<BiSolidDownArrow />
													</span>
												</p>
											) : (
												<p className="flex justify-start items-center gap-2">
													{item}{" "}
													<span onClick={sortDataByLoanAmountReverse} className="cursor-pointer">
														<BiSolidUpArrow />
													</span>
												</p>
											))}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{
								// console.log(dealList)
								dealList.length !== 0 &&
									dealList.map((item, i) => (
										<tr className="border-b-2 border-base-300" key={i}>
											<td>
												{new Date(item?.createdDate).toLocaleDateString("en-UK", {
													day: "numeric",
													month: "numeric",
													year: "numeric",
												})}
											</td>
											<td>{item["funder"]}</td>
											<td className="w-[300px]">{item["companyname"]}</td>
											<td>£ {parseInt(item["loanamount"]).toString() !== "NaN" ? parseInt(item["loanamount"]).toLocaleString("en-US", { style: "decimal", useGrouping: true }) : "0"}</td>
											<td>£ {parseInt(item["repayment"]).toString() !== "NaN" ? parseInt(item["repayment"]).toLocaleString("en-US", { style: "decimal", useGrouping: true }) : "0"}</td>
											<td>{item["factor"]}</td>
											<td>{item["comrate"]}</td>
											<td>£ {parseInt(item["comamo"]).toString() !== "NaN" ? parseInt(item["comamo"]).toLocaleString("en-US", { style: "decimal", useGrouping: true }) : "0"}</td>
											<td>{item["status"]}</td>
											<td>{item["closer"]}</td>

											<td className="flex items-center">
												<button onClick={() => handleEditBook(item)} className="btn btn-xs">
													EDIT
												</button>
												<button onClick={() => handleViewRequest(item)} className="btn btn-xs ml-3">
													VIEW
												</button>
												<span onClick={() => deleteRequest(item?._id)} className="ml-4 cursor-pointer text-[#e63a27] text-2xl">
													<HiTrash></HiTrash>
												</span>
											</td>
										</tr>
									))
							}
						</tbody>
					</table>
				</div>
			</div>
			<dialog id="viewDeal" className="modal w-[100%] max-w-[100%] mx-auto rounded">
				<form method="dialog" className="modal-box w-[900px] max-w-[900px] p-10">
					<ViewDeal item={viewDeal} />
					<div className="mt-3 text-right">
						{/* if there is a button in form, it will close the modal */}
						<button className="button !text-[#000] !bg-[#f2f2f2] ">Close</button>
					</div>
				</form>
			</dialog>
			<dialog id="editBook" className="modal w-[100%] max-w-[100%] mx-auto rounded">
				<EditBook item={editBook} setRefresh={setRefresh} refresh={refresh} closeModal={closeModal} setDealList={setDealList} dealList={dealList} />
			</dialog>
			<dialog id="deal" className="modal w-[900px] max-w-[900px] mx-auto">
				<form onSubmit={(e) => submitDeals(e)} method="dialog" className="modal-box p-10 w-[700px] max-w-[700px] rounded-lg">
					<div className="grid grid-cols-2 gap-3">
						<div className="flex flex-col">
							<label className="lebel">Funder:</label>
							<select name="funder" className="input border-gray-300" required>
								<option value={null} defaultValue>
									Funder
								</option>
								<option value="Liberis">Liberis</option>
								<option value="Youlend">Youlend</option>
								<option value="365 Finance">365 Finance</option>
								<option value="Sedge">Sedge</option>
								<option value="Capify">Capify</option>
								<option value="Playter">Playter</option>
								<option value="Penny">Penny</option>
								<option value="IWOCA">IWOCA</option>
								<option value="Swoop">Swoop</option>
								<option value="Funding Alt">Funding Alt</option>
								<option value="Seneca">Seneca</option>
								<option value="Maxcap">Maxcap</option>
								<option value="Swiftfund">Swiftfund</option>
								<option value="Funding Circle">Funding Circle</option>
								<option value="Bizcap">Bizcap</option>
								<option value="Sapi">Sapi</option>
							</select>
						</div>
						<div className="flex flex-col">
							<label className="lebel">Company Name:</label>
							<input name="companyname" type="text" placeholder="Company Name" className="input border-gray-300" required />
						</div>
						<div className="flex flex-col">
							<label className="lebel">Loan Amount:</label>
							<input name="loanamount" type="number" placeholder="Loan Amount" className="input border-gray-300" required />
						</div>
						<div className="flex flex-col">
							<label className="lebel">Repayment:</label>
							<input name="repayment" type="number" placeholder="Repayment" className="input border-gray-300" required />
						</div>
						<div className="flex flex-col">
							<label className="lebel">Factor:</label>
							<input name="factor" type="text" placeholder="Factor" className="input border-gray-300" required />
						</div>
						<div className="flex flex-col">
							<label className="lebel">Com Rate:</label>
							<input name="comrate" type="text" placeholder="Com Rate" className="input border-gray-300" required />
						</div>
						<div className="flex flex-col">
							<label className="lebel">Com Amo:</label>
							<input name="comamo" type="number" placeholder="Com Amo" className="input border-gray-300" required />
						</div>
						<div className="flex flex-col">
							<label className="lebel">Status:</label>
							<select name="status" className="input border-gray-300" required>
								<option value={null} defaultValue>
									Status
								</option>
								<option value="TBC">TBC</option>
								<option value="Funded">Funded</option>
								<option value="Renewed">Renewed</option>
								<option value="Dead">Dead</option>
								<option value="Moved and Ready">Moved and Ready</option>
							</select>
						</div>
						<div className="flex flex-col">
							<label className="lebel">Closer:</label>
							<select name="closer" className="input border-gray-300" required>
								<option value={null} defaultValue>
									Closer
								</option>
								<option value="Wayne">Wayne</option>
								<option value="Jon">Jon</option>
								<option value="Milly">Millie</option>
								<option value="Smit">Smit</option>
								<option value="Ver">Virgil</option>
								<option value="Dapo">Dapo</option>
								<option value="Don">Don</option>
								<option value="Chris">Chris</option>
								<option value="Maria">Maria</option>
								<option value="Mark">Mark</option>
								<option value="Myca">Myca</option>
								<option value="Reggie">Reggie</option>
								<option value="Tracy">Tracy</option>
								<option value="Support">Support</option>
								<option value="New Business">New Business</option>
								<option value="Jay">Jay</option>
								<option value="Lou">Lou</option>
								<option value="Juniel">Juniel</option>
								<option value="Paul">Paul</option>
								<option value="Josh">Josh</option>
								<option value="Aidan">Aidan</option>
							</select>
						</div>
						<div className="flex flex-col">
							<label className="lebel">Date:</label>
							<input name="date" type="date" placeholder="Date" className="input w-full border-gray-300" required />
						</div>
					</div>
					<div className="flex flex-col mt-3">
						<label className="lebel">Note:</label>
						<input name="note" type="text" placeholder="Note" className="input  w-full border-gray-300" />
					</div>
					<div className="flex gap-3 mt-4">
						{/* <button onClick={() => closeModal("stl")} type='button' className='button basis-1/2'>cancel</button> */}
						<button type="button" onClick={() => closeModal("deal")} className="basis-1/2 button !text-[#000] !bg-[#f2f2f2] !rounded">
							Close
						</button>
						<button type="submit" className="button basis-1/2 !rounded">
							submit
						</button>
					</div>
				</form>
			</dialog>
		</div>
	);
};

export default Book;
